var content = require("!!../../../node_modules/postcss-loader/src/index.js??ref--5-1!./fontsize.css");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {"injectType":"singletonStyleTag"}

options.insert = "head";
options.singleton = true;

var update = require("!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
